import { CreateStarCategoryInterface } from '@interfaces/index';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Question {
  questionId: number;
  name: string;
  questionInfo: string;
  score: number;
  specialWeighting: boolean;
  color: string;
  answeredScore?: number;
  shareWith?: string;
}

export interface Category {
  name: string;
  color: string;
  id: number;
  questions: Question[];
}

export interface AssessmentInterface {
  assessment_id: number;
  questionnaire_id: number;
  user_id: number;
}

export type WizardStep = 'SCORE' | 'REVIEW' | 'ACTIONS';
export type WizardSubStep =
  | 'START'
  | 'QUESTIONS'
  | 'SCORE_SUBMIT'
  | 'INSTANT_HELP'
  | 'FOCUS_AREAS'
  | 'FINISH';

interface WizardState {
  categoriesWithQuestions: Category[] | [];
  wizardStep: WizardStep;
  wizardSubStep: WizardSubStep;
  thoughtsSharing: boolean;
  sliderStates: SliderValues;
  parsedCategory: CreateStarCategoryInterface[] | [];
  selectedAndParsedUpdatedCategories: CreateStarCategoryInterface[] | [];
  assessmentDetails: AssessmentInterface | null;
}

interface SliderValues {
  [key: number]: {
    questionId: number;
    questionText: string;
    score: number;
  };
}

export const DefaultDeepDiveValues = {
  SCORE: 7,
  SCORING_STEP: 'SCORE',
  REVIEWING_STEP: 'REVIEW',
  ACTIONS_STEP: 'ACTIONS',
  DONE_STEP: 'DONE',

  START_SUB_STEP: 'START',
  QUESTIONS_SUB_STEP: 'QUESTIONS',
  SCORES_SUBMIT_SUB_STEP: 'SCORE_SUBMIT',
  INSTANT_HELP_SUB_STEP: 'INSTANT_HELP',
  FOCUS_AREAS_SUB_STEP: 'FOCUS_AREAS',
};

const initialState: WizardState = {
  categoriesWithQuestions: [],
  wizardStep: 'SCORE',
  wizardSubStep: 'START',
  thoughtsSharing: false,
  sliderStates: [],
  parsedCategory: [],
  selectedAndParsedUpdatedCategories: [],
  assessmentDetails: null,
};

const deepDiveWizardSlice = createSlice({
  name: 'deepDiveWizard',
  initialState,
  reducers: {
    updateQuestionnaireData: (state, action: PayloadAction<Category[]>) => ({
      ...state,
      categoriesWithQuestions: action.payload,
    }),

    updateWizardStep: (state, action: PayloadAction<WizardStep>) => ({
      ...state,
      wizardStep: action.payload,
    }),

    updateWizardSubStep: (state, action: PayloadAction<WizardSubStep>) => ({
      ...state,
      wizardSubStep: action.payload,
    }),

    updateThoughtSharing: (state, action: PayloadAction<boolean>) => ({
      ...state,
      thoughtsSharing: action.payload,
    }),

    storeDeepDiveQuestionsSliderValues: (state, action: PayloadAction<SliderValues>) => ({
      ...state,
      sliderStates: action.payload,
    }),

    parseCategoryDetails: (state, action: PayloadAction<CreateStarCategoryInterface[]>) => ({
      ...state,
      parsedCategory: action.payload,
    }),

    updateSelectedCategoryQuestions: (
      state,
      action: PayloadAction<CreateStarCategoryInterface[]>,
    ) => ({
      ...state,
      selectedAndParsedUpdatedCategories: action.payload,
    }),

    updateDeepDiveAnsweredScore: (
      state,
      action: PayloadAction<{ categoryId: number; questionId: number; answeredScore: number }>,
    ) => {
      const { categoryId, questionId, answeredScore } = action.payload;

      const updatedCategories = state.categoriesWithQuestions.map((category) => {
        if (category.id === categoryId) {
          return {
            ...category,
            questions: category.questions.map((question) => {
              if (question.questionId === questionId) {
                return {
                  ...question,
                  answeredScore,
                };
              }

              return question;
            }),
          };
        }

        return category;
      });

      return {
        ...state,
        categoriesWithQuestions: updatedCategories,
      };
    },

    updateAssessmentDetails: (state, action: PayloadAction<AssessmentInterface>) => ({
      ...state,
      assessmentDetails: action.payload,
    }),

    resetWizard: () => initialState,
  },
});

export const deepDiveWizardActions = deepDiveWizardSlice.actions;

export default deepDiveWizardSlice;
