import { getEmployerResourceUsage } from '@api/EmployerNew';
import Card from '@components/Atoms/Card';
import { Grid, Skeleton, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const OverallDetailsSection: React.FunctionComponent<OverallDetailsSectionProps> = ({
  loading,
}) => {
  const [resourceUsage, setResourceUsage] = useState({
    actions: {
      actionsSet: 0,
      actionsCompleted: 0,
    },
    learningAndExperts: {
      hoursOfLearning: 0,
      expertBookingsCost: 0,
    },
    wellbeingHub: {
      resourcesAccessed: 0,
    },
    earlyWarning: {
      urgentHelpAccessed: 0,
      speakUps: 0,
    },
  });
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    getResources();
  }, []);

  const getResources = async () => {
    try {
      setDataLoading(true);
      const res = await getEmployerResourceUsage();
      setResourceUsage(res);
    } catch (err) {
      setResourceUsage({
        actions: {
          actionsSet: 0,
          actionsCompleted: 0,
        },
        learningAndExperts: {
          hoursOfLearning: 0,
          expertBookingsCost: 0,
        },
        wellbeingHub: {
          resourcesAccessed: 0,
        },
        earlyWarning: {
          urgentHelpAccessed: 0,
          speakUps: 0,
        },
      });
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <div className={styles.overallDetailsContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Card className={styles.actionsCardContainer}>
            <h6 className={styles.subTitle}>Actions</h6>
            <p className={styles.subDescription}>Total actions set and completed</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={1}>
                <Skeleton variant="circular" width={130} height={130} />
                <Skeleton variant="circular" width={130} height={130} />
              </Stack>
            ) : (
              <div className={styles.roundedCountSection}>
                <div className={styles.actionsSetSection}>
                  <h5>{resourceUsage.actions.actionsSet}</h5>
                  <p>actions set</p>
                </div>

                <div className={styles.actionsCompletedSection}>
                  <h5>{resourceUsage.actions.actionsCompleted}</h5>
                  <p>actions completed</p>
                </div>
              </div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.learningAndCoachingCardContainer}>
            <h6 className={styles.subTitle}>Learning & Experts</h6>
            <p className={styles.subDescription}>Total learning time and expert credits</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={1}>
                <Skeleton variant="circular" width={130} height={130} />
                <Skeleton variant="circular" width={130} height={130} />
              </Stack>
            ) : (
              <div className={styles.roundedCountSection}>
                <div className={styles.learningHoursSection}>
                  <h5>{resourceUsage.learningAndExperts.hoursOfLearning}</h5>
                  <p>hours of learning</p>
                </div>

                <div className={styles.coachingSessionsSection}>
                  <h5>{resourceUsage.learningAndExperts.expertBookingsCost}</h5>
                  <p>expert credits</p>
                </div>
              </div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.wellbeingHubCardContainer}>
            <h6 className={styles.subTitle}>Wellbeing hub</h6>
            <p className={styles.subDescription}>Total resources accessed</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={1} sx={{ marginTop: 6 }}>
                <Skeleton variant="circular" width={200} height={200} />
              </Stack>
            ) : (
              <div className={styles.roundedCountSection}>
                <div className={styles.accessedResourcesSection}>
                  <h5>{resourceUsage.wellbeingHub.resourcesAccessed}</h5>
                  <p>resources accessed</p>
                </div>
              </div>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card className={styles.earlyWarningCardContainer}>
            <h6 className={styles.subTitle}>Early warning</h6>
            <p className={styles.subDescription}>Urgent help and speak up</p>

            {dataLoading ? (
              <Stack direction="column" alignItems="center" gap={1}>
                <Skeleton variant="circular" width={130} height={130} />
                <Skeleton variant="circular" width={130} height={130} />
              </Stack>
            ) : (
              <div className={styles.roundedCountSection}>
                <div className={styles.urgentHelpSection}>
                  <h5>{resourceUsage.earlyWarning.urgentHelpAccessed}</h5>
                  <p>urgent help access</p>
                </div>

                <div className={styles.speakUpSectionSection}>
                  <h5>{resourceUsage.earlyWarning.speakUps}</h5>
                  <p>speak up</p>
                </div>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

OverallDetailsSection.defaultProps = {
  loading: false,
};

interface OverallDetailsSectionProps {
  loading?: boolean;
}

export default OverallDetailsSection;
