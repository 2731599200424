import React from 'react';
import { Helmet } from 'react-helmet-async';
import HeaderConfiguration from '../../components/DeepDiveQuestionContainer/Components/DeepDiveHeader/HeaderConfiguration';
import Footer from './Footer';
import styles from './index.module.scss';

const OnboardingLayout: React.FunctionComponent<OnboardingLayoutProps> = (props) => {
  const { children, title, description, showFooter = true } = props;

  return (
    <div className={styles.hsOnboardingLayout}>
      <Helmet>
        <title>{title}</title>
        {description && <meta name="description" content={description} />}
      </Helmet>

      <HeaderConfiguration />
      <div className={styles.hsOnboardingLayoutContent}>{children}</div>
      {showFooter && <Footer />}
    </div>
  );
};

OnboardingLayout.defaultProps = {
  description: '',
  showFooter: true,
};

interface OnboardingLayoutProps {
  children: React.ReactNode | undefined;
  title: string;
  description?: string;
  showFooter?: boolean;
}

export default OnboardingLayout;
