import menuImage from '@assets/images/menu-image.svg';
import { logoutUser } from '@helpers/auth.helper';
import { useAppSelector } from '@hooks/useAppSelector';
import useGoNative from '@hooks/useGoNative';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { Divider } from '@mui/material';
import classNames from 'classnames';
import { UserRoles } from 'custom.d';
import React, { useMemo } from 'react';
import { Sidebar, useProSidebar } from 'react-pro-sidebar';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { RootState } from 'store';
import styles from './index.module.scss';

const commonLinks = (
  <>
    <li>
      <Link to="/what-is-hapstar">What is hapstar?</Link>
    </li>
    <li>
      <Link to="/feedback-and-suggestions">Contact us</Link>
    </li>
  </>
);

const Sidenav: React.FunctionComponent = () => {
  const { toggled, toggleSidebar } = useProSidebar();
  const history = useHistory();
  const { isDesktop, isMobile } = useWindowDimensions();
  const isAuthenticated = Boolean(localStorage.getItem('token'));
  let hasEmployerProfile = false;
  let hasSuperAdminProfile = false;
  const sidebarClasses = classNames([styles.hsSidenav, toggled && styles.Toggled]);
  const location = useLocation();
  const isMobileApp = useGoNative();
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);

  const handleLogout = () => {
    logoutUser();
  };

  const handleClick = () => {
    toggleSidebar(!toggled);
  };

  hasEmployerProfile = Boolean(
    JSON.parse(localStorage.getItem('user') || '{}')?.role === UserRoles.ADMIN ||
      JSON.parse(localStorage.getItem('user') || '{}')?.role === UserRoles.SUPER_ADMIN,
  );

  hasSuperAdminProfile = Boolean(
    JSON.parse(localStorage.getItem('user') || '{}')?.role === UserRoles.SUPER_ADMIN,
  );

  const isEmployerPage = useMemo(
    () => location.pathname.split('/')[1] === 'employer',
    [location.pathname],
  );

  const isSuperAdminPage = useMemo(
    () => location.pathname.split('/')[1] === 'management',
    [location.pathname],
  );

  return (
    <>
      <Sidebar className={sidebarClasses} backgroundColor="#0072B0" width="0px">
        <div
          style={{
            display: 'flex',
            cursor: 'pointer',
            width: '100%',
            justifyContent: 'end',
            padding: '10px 20px',
            fontWeight: '600',
            fontSize: 30,
            color: 'white',
          }}
          onClick={() => toggleSidebar(!toggled)}
        >
          x
        </div>
        <nav>
          <ul>
            {isDesktop && <li className={styles.menuTitle}>Menu</li>}
            {isDesktop && <li className={styles.divider} />}
            {commonLinks}
          </ul>
          {isDesktop && <img src={menuImage} alt="" />}
          <ul>
            <li className={`${styles.divider} ${styles.dividerSm}`} />
            {!isAuthenticated && (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
            {isAuthenticated && hasEmployerProfile && !isMobileApp && !isSuperAdminPage && (
              <li>
                <Link to={isEmployerPage ? '/' : '/employer/homepage'}>
                  {isEmployerPage ? 'Switch to Employee portal' : 'Switch to Employer portal'}
                </Link>
              </li>
            )}

            {isSuperAdminPage && (
              <>
                <li>
                  <Link to="/">Switch to Employee portal</Link>
                </li>
                <li>
                  <Link to="/employer/homepage">Switch to Employer portal</Link>
                </li>
              </>
            )}

            {hasSuperAdminProfile && !isSuperAdminPage && (
              <li>
                <Link to="/management/homepage">Switch to Management portal</Link>
              </li>
            )}

            {isDesktop && <li className={styles.divider} />}

            {isAuthenticated && (
              <li>
                <a onClick={handleLogout}>Log out</a>{' '}
              </li>
            )}
          </ul>
          {isMobile && <img src={menuImage} alt="Menu shapes." />}
        </nav>
      </Sidebar>

      {toggled && <div className={styles.hsSidenavOverlay} onClick={handleClick} />}
    </>
  );
};

export default Sidenav;
