import { getCategoriesWithQuestions } from '@api/categories';
import { isUserOnBoarded } from '@api/users';
import DeepDiveQuestionContainerMobile from '@components/DeepDiveQuestionContainer/Container';
import { CreateStarContext } from '@context/CreateStarContext';
import {
  SET_FOCUS_AREAS,
  SET_GRAPH_DATA,
  SET_IS_USER_ONBOARDING,
  useGlobalState,
} from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useDevFeatureToggle from '@hooks/useDevFeatureToggle';
import useWindowDimensions from '@hooks/useWindowDimensions';
import OnboardingLayout from '@layouts/OnboardingLayout/indexNew';
import React, { useContext, useEffect } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useHistory } from 'react-router';
import { RootState } from 'store';
import { Category, deepDiveWizardActions } from 'store/Slices/DeepDiveWizardSlice';

const CreateStarNew: React.FunctionComponent = () => {
  const reduxDispatch = useAppDispatch();

  const { categoryQuestion, setCategoryQuestion } = useContext(CreateStarContext);
  const [state, dispatch] = useGlobalState();
  const { isMobile } = useWindowDimensions();
  const { activeSubNav, toggleSubNav } = useContext(SecondaryNavigationContext);
  const history = useHistory();

  const token = localStorage.getItem('token');
  const devEnabled = useDevFeatureToggle();
  const deepDiveWizard = useAppSelector((st: RootState) => st.deepDiveWizard);

  useEffect(() => {
    setCategoryQuestion(
      categoryQuestion.map((i: any, key) => {
        const newStructure = categoryQuestion[key];
        newStructure.data = [0, 0, 0, 0];
        newStructure.questions = i.questions.map((o: any) => ({
          ...o,
          score: 0,
          isSelected: false,
        }));

        return newStructure;
      }),
    );

    dispatch({ type: SET_FOCUS_AREAS, payload: [] });

    dispatch({ type: SET_GRAPH_DATA, payload: { assesmentId: 0, date: '', index: 0 } });
  }, []);

  useEffect(() => {
    if (state.currentTab?.id) {
      toggleSubNav(state.currentTab);
    }
  }, [state.currentTab]);

  useEffect(() => {
    const getIsUserOnboarded = async () => {
      const data = await isUserOnBoarded();
      dispatch({ type: SET_IS_USER_ONBOARDING, payload: data });
    };

    if (token) {
      getIsUserOnboarded();
    }
  }, [dispatch, token]);

  useEffect(() => {
    const getCategoriesData = () => {
      getCategoriesWithQuestions(token, activeSubNav.id).then((categories: Category[]) => {
        reduxDispatch(deepDiveWizardActions.updateQuestionnaireData(categories));
      });
    };

    if (token && deepDiveWizard.categoriesWithQuestions.length === 0) {
      getCategoriesData();
    }
  }, [activeSubNav.id, token, deepDiveWizard.categoriesWithQuestions]);

  return (
    <ProSidebarProvider>
      <OnboardingLayout title="Create My Star" showFooter={false}>
        <div className="container-fluid">
          <div>
            <div>
              <DeepDiveQuestionContainerMobile />
            </div>
          </div>
        </div>
      </OnboardingLayout>
    </ProSidebarProvider>
  );
};

export default CreateStarNew;
