import { getCompareScoreWithPrevious } from '@api/assessment';
import { getScore } from '@api/score';
import arrowUp from '@assets/images/icons/arrow-up.svg';
import completeImage from '@assets/images/star-create-success.svg';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import useWindowDimensions from '@hooks/useWindowDimensions';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.scss';

const ScoreSubmitStep: React.FunctionComponent = () => {
  const classCols = 'col-12 col-sm-12 col-md-12 col-lg-6';
  const styleClasses = classNames([styles.hsStarCompleted, 'container-fluid']);
  const colStyles = classNames([classCols, styles.hsStarCompletedCol]);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [diff, setDiff] = useState(0) as any;
  const [error, setError] = useState(null) as any;
  const [avrScore, setAvrScore] = useState(0) as any;
  const { isMobile } = useWindowDimensions();
  let h1;
  let h2;
  let p;

  useEffect(() => {
    const getAvrScore = async () => {
      const { data } = await getScore(activeSubNav.id);
      setAvrScore(data.average);
    };

    const getDiffScore = async () => {
      const data = await getCompareScoreWithPrevious(activeSubNav.id);

      if (data?.message) {
        setError(data?.message);
      }

      setDiff(data.scoreDifference);
    };

    getAvrScore();
    getDiffScore();
  }, [activeSubNav.id]);

  if (diff === 0) {
    h1 = `The results are in...`;
    h2 = `Your overall score has remained the same. Keep going, happiness is a journey.`;
    p = `This is your chance to review your scores, pick some really important focus areas, and work on getting that happiness level up.`;
  } else if (diff > 0) {
    h1 = 'You did it!';
    h2 = `That's an increase of ${diff?.toFixed(0)}`;
    p = `You're on a positive trajectory with your happiness. Let's keep up the amazing work.`;
  } else if (diff < 0) {
    h1 = `The results are in...`;
    h2 = `Your overall score has dropped since last time, but that's ok, happiness is a journey.`;
    p = `This is your chance to review your scores, pick some really important focus areas, and work on getting that happiness level back up.`;
  } else {
    h1 = `You did it!`;
    h2 = `You've completed your first overall ${activeSubNav?.name
      ?.toLowerCase()
      ?.replace('star', '')}    deep dive`;
    p = `The score is entirely personal to you and there is no 'good' or 'bad', it's simply your starting point on this journey. Now let's go focus in on some key drivers that we can take action on.`;
  }

  return (
    <div className={styleClasses}>
      <div className="row">
        <div className={colStyles}>
          <h1>{h1}</h1>
          <h2>{h2}</h2>
          <p>{p}</p>
        </div>
        <div className={colStyles}>
          {avrScore && <span>{avrScore}</span>}
          <div style={{ position: 'relative' }}>
            <img
              src={completeImage}
              alt="You did it!"
              style={{ width: isMobile ? 230 : undefined }}
            />
            {!error && (
              <div
                style={{
                  position: 'absolute',
                  bottom: isMobile ? 0 : 50,
                  right: -20,
                  height: isMobile ? 80 : 122,
                  width: isMobile ? 80 : 122,
                  border: '4px solid #019DF2',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 99,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{ fontSize: isMobile ? 22 : 32, color: '#505050', fontWeight: 'bolder' }}
                >
                  {diff > 0 ? 'Up' : diff < 0 ? 'Down' : 'Same'}
                </div>
                <div
                  style={{
                    padding: isMobile
                      ? '0px 5px'
                      : diff < 0
                      ? '5px 8px'
                      : diff > 0
                      ? '5px 8px'
                      : '5px 20px',
                    backgroundColor: diff < 0 ? '#DC0808' : diff > 0 ? '#04BC00' : '#828282',
                    borderRadius: 15,
                    fontSize: isMobile ? 12 : 16,
                    fontWeight: 'bolder',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>{diff < 0 ? diff?.toFixed(0) : diff > 0 ? diff?.toFixed(0) : diff}</div>
                  <img
                    src={arrowUp}
                    alt="arrow up"
                    style={{
                      height: '20px',
                      width: '100%',
                      rotate: diff < 0 ? '180deg' : diff > 0 ? undefined : '90deg',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScoreSubmitStep;
