import { parseScores } from '@helpers/parser.helper';
import { CategoryInterface, CreateStarCategoryInterface } from '@interfaces/index';
import { getToken } from './config';
import { createHeaders } from './httpHeaderConfig';

export const getCategories = async (token: any, questionnaireId = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questionnaire-categories-questions/${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );
  const result: CategoryInterface[] = await response.json();
  const data: CreateStarCategoryInterface[] = parseScores(result);

  return data;
};

export const getCategoriesWithQuestions = async (token: any, questionnaireId = 1) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/questionnaire-categories-questions/${questionnaireId}`,
    {
      headers: createHeaders({ Authorization: `Bearer ${getToken()}` }),
    },
  );

  const result: CategoryInterface[] = await response.json();

  return result;
};
