import homeActive from '../assets/images/icons/home-active.svg';
import homeInactive from '../assets/images/icons/home-inactive.svg';
import analyticsInactive from '../assets/images/icons/analytics-inactive.svg';
import analyticsActive from '../assets/images/icons/analytics-active.svg';
import commentsInactive from '../assets/images/icons/comments-inactive.svg';
import commentsActive from '../assets/images/icons/comments-active.svg';
import usersInactive from '../assets/images/icons/users-inactive.svg';
import usersActive from '../assets/images/icons/users-active.svg';
import learnActive from '../assets/images/icons/learn-active.svg';
import learnInactive from '../assets/images/icons/learn-inactive.svg';

export default [
  {
    name: 'Home',
    path: '/employer/homepage',
    exact: true,
    icon: homeInactive,
    activeIcon: homeActive,
  },
  {
    name: 'Analytics',
    path: '/employer/analytics',
    exact: false,
    icon: analyticsInactive,
    activeIcon: analyticsActive,
  },
  {
    name: 'Comments',
    path: '/employer/comments',
    exact: false,
    icon: commentsInactive,
    activeIcon: commentsActive,
  },
  {
    name: 'Employees',
    path: '/employer/users',
    exact: false,
    icon: usersInactive,
    activeIcon: usersActive,
  },
];
