import actionsActive from '../assets/images/icons/actions-active.svg';
import actionsInactive from '../assets/images/icons/actions-inactive.svg';
import homeActive from '../assets/images/icons/home-active.svg';
import homeInactive from '../assets/images/icons/home-inactive.svg';
import learnActive from '../assets/images/icons/learn-active.svg';
import learnInactive from '../assets/images/icons/learn-inactive.svg';
import starsActive from '../assets/images/icons/stars-active.svg';
import starsInactive from '../assets/images/icons/stars-inactive.svg';
import wellbeingActive from '../assets/images/icons/wellbeing-hub-active.svg';
import wellbeingInactive from '../assets/images/icons/wellbeing-hub-inactive.svg';

import expertsActive from '../assets/images/icons/experts-active.svg';
import expertsInactive from '../assets/images/icons/experts-inactive.svg';

export default [
  {
    name: 'Home',
    mobileName: 'Home',
    path: '/',
    exact: true,
    icon: homeInactive,
    activeIcon: homeActive,
  },
  {
    name: 'Scores',
    mobileName: 'Scores',
    path: '/stars',
    exact: false,
    icon: starsInactive,
    activeIcon: starsActive,
  },
  {
    name: 'Plans',
    mobileName: 'Plans',
    path: '/improve-plans',
    exact: false,
    icon: actionsInactive,
    activeIcon: actionsActive,
  },
  {
    name: 'Hub',
    mobileName: 'Hub',
    path: '/wellbeing',
    exact: false,
    icon: wellbeingInactive,
    activeIcon: wellbeingActive,
  },
  {
    name: 'Learn',
    mobileName: 'Learn',
    path: '/posts',
    exact: false,
    icon: learnInactive,
    activeIcon: learnActive,
  },
  {
    name: 'Experts',
    mobileName: 'Experts',
    path: '/experts',
    exact: false,
    icon: expertsInactive,
    activeIcon: expertsActive,
  },
];
