import { CreateStarContext } from '@context/CreateStarContext';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import React, { useContext, useEffect, useState } from 'react';
import { RootState } from 'store';
import styles from './index.module.scss';

const data = [
  { id: 0, step: 1, title: 'Score', active: true, completed: false },
  { id: 1, step: 2, title: 'Review', active: false, completed: false },
  { id: 2, step: 3, title: 'Actions', active: false, completed: false },
];

const HeaderMobile: React.FunctionComponent = () => {
  const [steps, setSteps] = useState(data);
  const { currentStep } = useContext(CreateStarContext);
  const { isMobile } = useWindowDimensions();

  const deepDiveWizard = useAppSelector((state: RootState) => state.deepDiveWizard);

  useEffect(() => {
    if (deepDiveWizard.wizardStep === 'SCORE') {
      setSteps(
        data.map((item, index) => {
          const newItem = { ...item };
          newItem.active = false;
          newItem.completed = false;
          if (index === 0) {
            newItem.active = true;
            newItem.completed = false;
          }

          return newItem;
        }),
      );
    }

    if (deepDiveWizard.wizardStep === 'REVIEW') {
      setSteps(
        data.map((item, index) => {
          const newItem = { ...item };
          newItem.active = false;
          newItem.completed = index === 0;
          if (index === 1) {
            newItem.active = true;
            newItem.completed = false;
          }

          return newItem;
        }),
      );
    }

    if (deepDiveWizard.wizardStep === 'ACTIONS') {
      setSteps(
        data.map((item, index) => {
          const newItem = { ...item };
          newItem.active = false;
          newItem.completed = true;
          if (index === data.length - 1) {
            newItem.active = true;
            newItem.completed = false;
          }

          return newItem;
        }),
      );
    }
  }, [currentStep, deepDiveWizard]);

  const generateSteps = () =>
    steps.map((step) => (
      <li key={step.id}>
        <a
          href="#"
          className={`${step.active && styles.active} ${step.completed && styles.completed}`}
        >
          <span
            data-step={step.step}
            style={{
              height: isMobile ? 35 : undefined,
              width: isMobile ? 35 : undefined,
              fontSize: 22,
            }}
          />
          {step.title}
        </a>
      </li>
    ));

  return (
    <div className={styles.hsHeaderWizardContainer}>
      <ul className={styles.hsHeaderWizard}>{generateSteps()}</ul>
    </div>
  );
};

export default HeaderMobile;
