import Img from '@components/Atoms/Img';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { ExpertsResultsResponse } from '@interfaces/index';
import { Skeleton } from '@mui/material';
import { USER_NOT_FOUND_IMAGE } from 'custom.d';
import React from 'react';
import Carousel from 'react-multi-carousel';
import styles from './index.module.scss';

const ExpertsCarousel: React.FunctionComponent<ConfirmBookingProps> = ({
  experts,
  loading = true,
}) => {
  const { isDesktop, isTablet } = useWindowDimensions();
  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024,
      },
      items: 3,
      partialVisibilityGutter: 20,
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464,
      },
      items: 2,
      partialVisibilityGutter: 30,
    },
  };

  function truncateText(text: string, wordLimit: number) {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(' ')}...`;
    }

    return text;
  }

  return (
    <>
      <div className={styles.expertsSection}>
        <div className={styles.carouselContainer}>
          <Carousel
            additionalTransfrom={2}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className={styles['gd-carousel']}
            containerClass=""
            ssr
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClass={styles.carouselItemMulti}
            keyBoardControl
            minimumTouchDrag={30}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
            partialVisible
          >
            {loading
              ? Array.from({ length: isDesktop ? 10 : isTablet ? 2 : 1 }).map((_, index) => (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={200}
                    key={index}
                    className={styles.itemLoading}
                  />
                ))
              : experts.results.map((item, index) => (
                  <div
                    className={styles.expertItem}
                    key={index}
                    onClick={() => {
                      window.location.href = `/expert/${item.id}`;
                    }}
                  >
                    <div className={styles.expertItemContainer}>
                      <Img
                        src={
                          item?.expertDetail?.profileImage
                            ? `https://${item.expertDetail.profileImage}`
                            : USER_NOT_FOUND_IMAGE
                        }
                        alt={item.expertDetail?.displayName || ''}
                        className={styles.expertImg}
                      />
                      <div className={styles.expertTextContainer}>
                        <h3
                          className={styles.expertTitle}
                        >{`${item.firstName} ${item.lastName}`}</h3>

                        <p className={styles.expertDescription}>
                          {truncateText(item?.expertDetail?.headline || '', 20)
                            .split('\\n')
                            .map((line, i) => (
                              <p key={i}>{line}</p>
                            ))}
                        </p>

                        {/* <div className={styles.roleContainer}>{item.expertDetail.countryName}</div> */}
                      </div>
                    </div>

                    <div className={styles.arrowButton}>
                      <div>
                        <svg
                          style={{ cursor: 'pointer' }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 87 72"
                        >
                          <polygon
                            className={styles.blogArrowBg}
                            id="Path"
                            points="86.96 1.33 1.15 21.53 31.69 71.92 86.96 71.97 86.96 1.33"
                          />
                          <g id="Arrow">
                            <g id="Group-2">
                              <polygon points="50.66 49.89 48.96 48.19 61.18 35.98 48.96 23.76 50.66 22.06 64.57 35.98 50.66 49.89" />
                              <rect x="36.75" y="34.78" width="26.13" height="2.4" />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                ))}
          </Carousel>
        </div>
      </div>
    </>
  );
};

ExpertsCarousel.defaultProps = {
  loading: true,
};

interface ConfirmBookingProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  experts: ExpertsResultsResponse;
  loading?: boolean;
}

export default ExpertsCarousel;
