import {
  getDeepDives,
  getImproveActionsForDashboard,
  getUserImprovePlans,
  getUserReflections,
  updateActionPlan,
} from '@api/actionPlan';
import { getAllExperts } from '@api/experts';
import { getHelpResponses, updateSpeakupResponse } from '@api/instantHelp';
import { getAllLearningByFilterCriteria } from '@api/learningContent';
import { updateResponse } from '@api/responses';
import {
  getShortStarAssessmentScoreByQuestionId,
  getShortStarAssessmentScores,
  getShortStarScores,
} from '@api/shortStar';
import { getThoughtsByFilter } from '@api/thoughts';
import { getUserById } from '@api/users';
import BodySoundMeditation from '@assets/audio/body-and-sound-meditation.mp3';
import BodyScanSleep from '@assets/audio/body-scan-for-sleep.mp3';
import ShortBodyScan from '@assets/audio/body-scan.mp3';
import BreathingMeditation from '@assets/audio/breathing-meditation.mp3';
import LovingKindnessMeditation from '@assets/audio/loving-kindness-meditation.mp3';
import MeditationWorkingWithDifficulties from '@assets/audio/meditation-for-working-with-difficulties.mp3';
import EmptyPlans from '@assets/images/empty-plans.svg';
import DoubleTick from '@assets/images/icons/double-tick.svg';
import QuestionMarkIcon from '@assets/images/icons/question-mark-white-filled.svg';
import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import DashboardAreaChart from '@components/Molecules/DashboardAreaChart';
import DeepDives from '@components/Molecules/Employee/DeepDives';
import DesktopExploreSection from '@components/Molecules/Employee/DesktopExploreSection';
import ImprovePlan from '@components/Molecules/Employee/Improve';
import MusicSection from '@components/Molecules/Employee/MusicSection';
import NotificationSection from '@components/Molecules/Employee/NotificationSection';
import QuoteSection from '@components/Molecules/Employee/QuoteSection';
import ReflectionSection from '@components/Molecules/Employee/ReflectionSection';
import ScoreGaugeSection from '@components/Molecules/Employee/ScoreGaugeSection';
import ThoughtsResponses from '@components/Molecules/Employee/ThoughtsResponses';
import ExpertsCarousel from '@components/Organisms/Experts/ExpertsCarousel';
import LearningArticleCard from '@components/Organisms/Learning/LearningArticleCard';
import ShortStarModal from '@components/Organisms/ShortStarModal';
import PopUpModal from '@components/PopUpModal';
import { Colors } from '@enums/Colors';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import { ExpertsResultsResponse } from '@interfaces/index';
import MainLayout from '@layouts/MainLayout/indexNew';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {
  PaginationInterface,
  SpeakUpResponseInterface,
  ThoughtDataInterface,
  ThoughtResponseInterface,
  UserImprovePlanActions,
} from 'custom';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { configurationSliceActions } from 'store/Slices/configurationsSlice';
import NotificationActiveIcon from '../../assets/images/icons/notifications-active-outlined.svg';
import Quote from '../../assets/images/quote.svg';
import styles from './index.module.scss';

const musicData = [
  {
    id: 1,
    name: 'Short Body Scan',
    description:
      'This short mindfulness technique helps you to relax, reducing tension and promoting self-awareness.',
    src: ShortBodyScan,
  },
  {
    id: 2,
    name: 'Relieve your stress',
    description:
      'This practice promotes mindfulness through breath regulation, calming the body and quietening the mind.',
    src: BreathingMeditation,
  },
  {
    id: 3,
    name: 'Body and Sound Meditation',
    description:
      'This short mindfulness technique allows you to take a few minutes for yourself to be present.',
    src: BodySoundMeditation,
  },
  {
    id: 4,
    name: 'Meditation for Working with Difficulties',
    description: 'This meditation may ground you during uncomfortable times.',
    src: MeditationWorkingWithDifficulties,
  },
  {
    id: 5,
    name: 'Loving Kindness Meditation',
    description:
      'Take some time to appreciate yourself and those around you with this short practice.',
    src: LovingKindnessMeditation,
  },
  {
    id: 6,
    name: 'Body Scan for Sleep',
    description:
      'This slightly longer meditation will prepare you for a restful sleep, releasing tension in your body and slowing your breathing down.',
    src: BodyScanSleep,
  },
];

const quoteData = [
  {
    id: 1,
    quote: `The happiest people don't have the best of everything, they just make the best of everything.`,
    author: 'Mahatma Gandhi',
  },
  {
    id: 2,
    quote:
      'There is no happiness like that of being loved by your fellow-creatures, and feeling that your presence.',
    author: 'Charlotte Bronte',
  },
  {
    id: 3,
    quote: 'The beginning is the most important part of the work.',
    author: 'Plato',
  },
  {
    id: 4,
    quote:
      'Your limitation — it’s only your imagination. Push yourself, because no one else is going to do it for you.',
    author: 'Anonymous',
  },
  {
    id: 5,
    quote: 'Great things never come from comfort zones. Dream it. Wish it. Do it.',
    author: 'Anonymous',
  },
  {
    id: 6,
    quote:
      'The best and most beautiful things in the world cannot be seen or even touched—they must be felt with the heart.',
    author: 'Helen Keller',
  },
  {
    id: 7,
    quote:
      'Success is not the key to happiness. Happiness is the key to success. If you love what you are doing, you will be successful.',
    author: 'Albert Schweitzer',
  },
  {
    id: 8,
    quote:
      'Let us be grateful to the people who make us happy; they are the charming gardeners who make our souls blossom.',
    author: 'Marcel Proust',
  },
  {
    id: 9,
    quote:
      'Gratitude makes sense of our past, brings peace for today, and creates a vision for tomorrow.',
    author: 'Melody Beattie',
  },
  {
    id: 10,
    quote:
      'Reflect upon your present blessings, of which every man has plenty; not on your past misfortunes, of which all men have some.',
    author: 'Charles Dickens',
  },
  {
    id: 11,
    quote: 'Be not afraid of growing slowly, be afraid only of standing still.',
    author: 'Chinese Proverb',
  },
  {
    id: 12,
    quote: 'The mind is everything. What you think you become.',
    author: 'Buddha',
  },
  {
    id: 13,
    quote: 'Take care of your body. It’s the only place you have to live.',
    author: 'Jim Rohn',
  },
  {
    id: 14,
    quote: 'Happiness is the highest form of health.',
    author: 'Dalai Lama',
  },
  {
    id: 15,
    quote: 'We are shaped and fashioned by those we love.',
    author: 'Goethe',
  },
  {
    id: 16,
    quote: 'The only way to do great work is to love what you do.',
    author: 'Steve Jobs',
  },
  {
    id: 17,
    quote: 'Exploration is really the essence of the human spirit.',
    author: 'Frank Borman',
  },
  {
    id: 18,
    quote:
      'What we do for ourselves dies with us. What we do for others and the world remains and is immortal.',
    author: 'Albert Pike',
  },
  {
    id: 19,
    quote: 'It does not matter how slowly you go as long as you do not stop.',
    author: 'Confucius',
  },
  {
    id: 20,
    quote: 'Creativity is thinking up new things. Innovation is doing new things.',
    author: 'Theodore Levitt',
  },
  {
    id: 21,
    quote:
      'Do not go where the path may lead, go instead where there is no path and leave a trail.',
    author: 'Ralph Waldo Emerson',
  },
  {
    id: 22,
    quote: 'The best way to find yourself is to lose yourself in the service of others.',
    author: 'Mahatma Gandhi',
  },
  {
    id: 23,
    quote:
      'Joy is what happens to us when we allow ourselves to recognize how good things really are.',
    author: 'Marianne Williamson',
  },
  {
    id: 24,
    quote:
      'Breathe. Let go. And remind yourself that this very moment is the only one you know you have for sure.',
    author: 'Oprah Winfrey',
  },
  {
    id: 25,
    quote:
      'To keep the body in good health is a duty... Otherwise we shall not be able to keep our mind strong and clear.',
    author: 'Buddha',
  },
  {
    id: 26,
    quote: 'Gratitude turns what we have into enough, and more.',
    author: 'Melody Beattie',
  },
  {
    id: 27,
    quote: 'Growth and comfort do not coexist.',
    author: 'Ginni Rometty',
  },
  {
    id: 28,
    quote: 'Do not save what is left after spending, but spend what is left after saving.',
    author: 'Warren Buffett',
  },
  {
    id: 29,
    quote: 'No one has ever become poor by giving.',
    author: 'Anne Frank',
  },
  {
    id: 30,
    quote: 'Great things never come from comfort zones. Dream it. Wish it. Do it.',
    author: 'Anonymous',
  },
  {
    id: 31,
    quote: 'Alone, we can do so little; together, we can do so much.',
    author: 'Helen Keller',
  },
];

const initInfoDialog = {
  open: false,
  top: '',
  right: '',
  firstDescription: '',
  description: '',
  title: '',
};

const EmployeeHome: React.FunctionComponent = () => {
  const user = localStorage.getItem('user');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState<any>(null);
  const [infoDialog, setInfoDialog] = useState<{
    open: boolean;
    top: string;
    right: string;
    firstDescription: string;
    description: string;
    title: string;
  }>(initInfoDialog);

  const [userImprovePlans, setUserImprovePlans] = useState<UserImprovePlanActions[] | []>([]);
  const [userReflections, setUserReflections] = useState<UserReflection[] | []>([]);
  const [exploreSectionEnabled, setExploreSectionEnabled] = useState<boolean>(true);
  const [dashboardActions, setDashboardActions] = useState<{
    progress: number;
    actions: any;
    plans: any;
  }>({
    progress: 0,
    actions: [],
    plans: [],
  });

  const [assessmentScores] = useState<AssessmentScores[]>([]);
  const [scoreChartData, setScoreChartData] = useState({ scores: [], dates: [] });
  const [latestScore, setLatestScore] = useState(null);
  const [posts, setPosts] = useState<{ id: number; innerImageUrl: string; title: string }[]>([]);
  const [recommendedPosts, setRecommendedPosts] = useState<
    { id: number; innerImageUrl: string; title: string }[]
  >([]);
  const [loadingPosts, setLoadingPosts] = useState<boolean>(true);
  const [errorPosts, setErrorPosts] = useState<boolean>(false);
  const [thoughtsResponses, setThoughtsResponses] = useState<ThoughtDataInterface[]>([]);
  const [speakUpResponses, setSpeakUpResponses] = useState<SpeakUpResponseInterface[]>([]);
  const [allResponses, setAllResponses] = useState<CombinedCommentsArray>([]);
  const [deepDives, setDeepDives] = useState<DeepDive[]>([]);
  const [rerender, setRerender] = useState<boolean>(false);
  const [scoreGaugeLoading, setScoreGaugeLoading] = useState<boolean>(true);
  const [assessmentScoresLoading, setAssessmentScoresLoading] = useState<boolean>(true);
  const [reflectionsLoading, setReflectionsLoading] = useState<boolean>(true);
  const [quotesLoading, setQuotesLoading] = useState<boolean>(true);
  const [musicLoading, setMusicLoading] = useState<boolean>(true);
  const [improvementsLoading, setImprovementsLoading] = useState<boolean>(true);
  const [wellbeingLoading, setWellbeingLoading] = useState<boolean>(true);
  const [otherLoading, setOtherLoading] = useState<boolean>(true);
  const [shortStarModalOpen, setShortStarModalOpen] = useState<boolean>(false);
  const [userCompletedPlanCount, setUserCompletedPlanCount] = useState<number>(0);
  const hsConfigurations = useAppSelector((state: RootState) => state.configurations);

  const [shortStarScoreData, setShortStarScoreData] = useState<ShortStarScoreInterface[] | []>([]);
  const [shortStarScoreDataLoading, setShortStarScoreDataLoading] = useState<boolean>(true);
  const urlParams = new URLSearchParams(window.location.search);
  const checkInParam = urlParams.get('check-in');

  const [experts, setExperts] = useState<ExpertsResultsResponse>({ results: [], total: 0 });
  const [expertsLoading, setExpertsLoading] = useState(false);

  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);

  useEffect(() => {
    getUserDetails();
  }, [user]);

  useEffect(() => {
    if (companyConfigData.data.expertsEnabled) {
      getExperts();
    }
  }, [companyConfigData]);

  useEffect(() => {
    setOtherLoading(true);
    setMusicLoading(true);
    setQuotesLoading(true);

    getShortStarScoreData();
    getAssessmentScores();

    getImprovePlans();
    getReflections();
    getActions();
    getDeepDiveData();

    setTimeout(() => {
      setOtherLoading(false);
      setQuotesLoading(false);
    }, 1000);

    setTimeout(() => {
      setMusicLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (location.hash === '#responses') {
      setTimeout(() => {
        const targetElement = document.getElementById('responses');

        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 750);
    }
  }, [location]);

  useEffect(() => {
    const latestScoreValue = scoreChartData.scores[scoreChartData.scores.length - 1];

    setLatestScore(latestScoreValue);
  }, [scoreChartData]);

  useEffect(() => {
    getThoughtsByFilter(true, [1, 2, 3, 5, 6, 7], null, 'DESC').then(
      (data: ThoughtDataInterface[]) => {
        setThoughtsResponses(data);
      },
    );
  }, [rerender]);

  useEffect(() => {
    getHelpResponses(null, null, true).then((data: SpeakUpResponseInterface[]) => {
      setSpeakUpResponses(data);
    });
  }, []);

  useEffect(() => {
    const modifiedResponses = thoughtsResponses
      .filter((d) => d.response?.responseMessage && !d.responsesSeen)
      .map((res: ThoughtDataInterface) => {
        if (typeof res === 'object' && res !== null) {
          return {
            ...res,
            isSpeakup: false,
          };
        }

        return res;
      });

    const modifiedSpeakUpResponses = speakUpResponses
      .filter(
        (res: SpeakUpResponseInterface) =>
          typeof res === 'object' && res !== null && res.responded && !res?.response?.responseSeen,
      )
      .map((res: any) => ({
        ...res,
        isSpeakup: true,
      }));

    const combinedResponses = [...modifiedResponses, ...modifiedSpeakUpResponses];

    const sortedResponses = combinedResponses.sort((a, b) => {
      const dateA: string | undefined = a?.createdAt || a?.speakUpCreatedDate;
      const dateB: string | undefined = b?.createdAt || b?.speakUpCreatedDate;

      if (dateA && dateB) {
        return Number(new Date(dateB)) - Number(new Date(dateA));
      }

      return 0;
    });

    setAllResponses(sortedResponses);
  }, [speakUpResponses, thoughtsResponses]);

  const getUserDetails = async () => {
    if (user) {
      const userDet = JSON.parse(user);
      const res = await getUserById(userDet.id);
      setUserDetails(res);
    }
  };

  const getShortStarScoreData = async () => {
    setShortStarScoreDataLoading(true);
    const res = await getShortStarScores();
    setShortStarScoreData(res);
    setShortStarScoreDataLoading(false);
  };

  const getAssessmentScores = async () => {
    try {
      setScoreGaugeLoading(true);
      setAssessmentScoresLoading(true);

      const res = await getShortStarAssessmentScores();

      if (res.length > 0) {
        dispatch(configurationSliceActions.addLatestAssessmentScore(res[res.length - 1]));
      }

      const dt = res.reduce(
        (acc: { scores: any[]; dates: any[] }, item: { assessmentScore: any; createdAt: any }) => {
          acc.scores.push(item.assessmentScore);
          acc.dates.push(item.createdAt);

          return acc;
        },
        { scores: [], dates: [] },
      );

      setScoreChartData(dt);
    } catch (error) {
      // Handle error
    } finally {
      setScoreGaugeLoading(false);
      setAssessmentScoresLoading(false);
    }
  };

  const getReflections = async () => {
    try {
      setReflectionsLoading(true);
      const reflections = await getUserReflections({
        startDate: null,
        endDate: null,
        assessmentId: null,
        limit: 5,
        orderBy: 'created_at',
        order: 'DESC',
      });

      setUserReflections(reflections);
    } catch (error) {
      setUserReflections([]);
    } finally {
      setTimeout(() => {
        setReflectionsLoading(false);
      }, 1000);
    }
  };

  const getDeepDiveData = async () => {
    try {
      setWellbeingLoading(true);
      const data = await getDeepDives();
      setDeepDives(data);
    } catch (error) {
      setDeepDives([]);
    } finally {
      setTimeout(() => {
        setWellbeingLoading(false);
      }, 1000);
    }
  };

  const getActions = async () => {
    try {
      setImprovementsLoading(true);
      const res = await getImproveActionsForDashboard();

      const filteredActions = res?.data
        ?.flatMap((dt) =>
          dt.actions
            .filter((ac) => !ac.removed)
            .map((action) => ({
              ...action,
              questionColor: dt.questionColor,
            })),
        )
        .sort((a, b) => {
          if (a.improvePlanActionDueDays === b.improvePlanActionDueDays) {
            return 0;
          }

          // nulls sort after anything else
          if (a.improvePlanActionDueDays === null) {
            return 1;
          }

          if (b.improvePlanActionDueDays === null) {
            return -1;
          }

          return a.improvePlanActionDueDays > b.improvePlanActionDueDays ? 1 : -1;
        });

      const plans = res?.data?.map((dt) => ({
        id: dt.id,
        userId: dt.userId,
        questionId: dt.questionId,
        improvePlan: dt.improvePlan,
        questionColor: dt.questionColor,
        questionIcon: dt.questionIcon,
        progress: dt.progress,
        done: dt.done,
      }));

      setDashboardActions({ progress: res?.totalProgress, actions: filteredActions, plans });
    } catch (error) {
      setDashboardActions({ progress: 0, actions: [], plans: [] });
    } finally {
      setTimeout(() => {
        setImprovementsLoading(false);
      }, 1000);
    }
  };

  const getImprovePlans = async () => {
    try {
      const uPlans = await getUserImprovePlans(true);
      const uPlansCompleted = await getUserImprovePlans(false);

      setUserImprovePlans(uPlans.userImprovePlans);
      setUserCompletedPlanCount(uPlansCompleted?.userImprovePlans?.length);
    } catch (e) {
      setUserImprovePlans([]);
    }
  };

  function getColor(color: keyof typeof Colors): string {
    return Colors[color];
  }

  const getAllScores = async () => {
    await Promise.all(
      deepDives.map(async (plan) => {
        try {
          const assessmentScore = await getShortStarAssessmentScoreByQuestionId(plan.questionId);
          const updatedAssessmentScore = assessmentScore.map((score: any) => ({
            ...score,
            questionColor: plan.colorTheme,
            improvePlanName: plan.questionText,
          }));

          const series = {
            name: updatedAssessmentScore[0].improvePlanName,
            data: updatedAssessmentScore.map(
              (score: { questionScore: any }) => score.questionScore,
            ),
            color: getColor(updatedAssessmentScore[0].questionColor),
            createdAt: updatedAssessmentScore.map((score: { createdAt: any }) => score.createdAt),
          };

          assessmentScores.push(series);

          return series;
        } catch (e) {
          return [];
        }
      }),
    );
  };

  useEffect(() => {
    setAssessmentScoresLoading(true);
    getAllScores();

    setTimeout(() => {
      setAssessmentScoresLoading(false);
    }, 1000);
  }, [deepDives]);

  const getWpPosts = useCallback(() => {
    const getPostsAPI = async () => {
      try {
        const response = await getAllLearningByFilterCriteria({
          order: 'DESC',
          orderBy: 'contentCreatedAt',
          page: 1,
          perPage: 60,
          questionIds: [],
          tagIds: [],
        });

        const modifiedData =
          response?.data?.map((dt) => ({
            id: dt.id,
            innerImageUrl: dt.imageUrl,
            title: dt.title,
          })) || [];

        setPosts(modifiedData.slice(0, 3));
        setLoadingPosts(false);
      } catch (err) {
        setLoadingPosts(false);
        setErrorPosts(true);
      }
    };

    getPostsAPI();
  }, []);

  useEffect(() => {
    getWpPosts();
  }, [getWpPosts]);

  useEffect(() => {
    const getPostsAPI = async () => {
      try {
        const userImprovePlansIds: number[] = [];
        userImprovePlans.forEach((uIP) => {
          userImprovePlansIds.push(uIP.questionId);
        });

        const response = await getAllLearningByFilterCriteria({
          order: 'DESC',
          orderBy: 'contentCreatedAt',
          page: 1,
          perPage: 60,
          questionIds: userImprovePlansIds,
          tagIds: [],
        });

        const modifiedData =
          response?.data?.map((dt) => ({
            id: dt.id,
            innerImageUrl: dt.imageUrl,
            title: dt.title,
          })) || [];

        setRecommendedPosts(modifiedData.slice(0, 4));
        setLoadingPosts(false);
      } catch (err) {
        setLoadingPosts(false);
        setErrorPosts(true);
      }
    };

    getPostsAPI();
  }, [userImprovePlans]);

  const updateResponseAction = async (thoughtId: number) => {
    await updateResponse(thoughtId);
    setThoughtsResponses((prev) => {
      const filteredData = prev.filter((k: ThoughtDataInterface) => k.thoughtId !== thoughtId);
      if (prev) {
        return filteredData;
      }

      return prev;
    });
  };

  const seenAndArchiveSpeakUp = async (responseId: number) => {
    await updateSpeakupResponse(true, responseId);
    setSpeakUpResponses((prev) =>
      prev.filter((k: SpeakUpResponseInterface) => k.response?.responseId !== responseId),
    );
  };

  const handleUpdateAction = async (
    data: {
      id: number;
      actionType: string;
      dueDate: string | null;
      done: boolean | null;
      removed: boolean | null;
      start: boolean | null;
    },
    message?: string,
  ) => {
    try {
      await updateActionPlan(data);
      await getActions();
      toast.success(message);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message || `Error. Try again later`);
      } else {
        toast.error(`Error. Try again later`);
      }
    }
  };

  const notificationAction = dashboardActions?.actions[0];

  const handleRefresh = async () => {
    await setShortStarModalOpen(false);
    await window.location.reload();
  };

  const getExperts = async () => {
    try {
      setExpertsLoading(true);
      const response: ExpertsResultsResponse = await getAllExperts(null, null, 0, 999);
      setExperts(response);
    } catch {
      setExperts({ results: [], total: 0 });
    } finally {
      setExpertsLoading(false);
    }
  };

  return (
    <MainLayout title="Home" secondaryNavEnabled>
      {shortStarModalOpen &&
        ReactDOM.createPortal(
          <ShortStarModal modalOpen={shortStarModalOpen} onClose={handleRefresh} />,
          document.getElementById('short-star-modal')!,
        )}
      <div className={styles.desktopOrder}>
        <div className={styles.infoSection}>
          <div className="container">
            <div className={styles.employeeTopContainer}>
              {otherLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="50%"
                    height={80}
                    className={styles.loadUserWelcome}
                  />
                </>
              ) : (
                <h2 className={styles.title}>Welcome to Hapstar, {userDetails?.forename}</h2>
              )}

              <NotificationSection
                loading={improvementsLoading}
                icon={NotificationActiveIcon}
                button={
                  notificationAction
                    ? notificationAction?.details?.actionButton
                      ? notificationAction?.details?.actionButton
                      : 'Start Now'
                    : 'Create a plan'
                }
                handleClickAction={() => {
                  if (notificationAction) {
                    handleUpdateAction(
                      {
                        id: notificationAction?.details?.actionId,
                        actionType: notificationAction?.details?.actionType,
                        dueDate: null,
                        done: null,
                        removed: null,
                        start: true,
                      },
                      'Action started',
                    );

                    if (notificationAction?.details?.actionUrl) {
                      window.location.href = notificationAction?.details?.actionUrl;
                    }
                  } else {
                    setShortStarModalOpen(true);
                  }
                }}
                improvePlan={notificationAction}
              />
            </div>
            <Grid container spacing={2} className={styles.progressSection}>
              <Grid item xs={12} md={3}>
                <ScoreGaugeSection
                  loading={scoreGaugeLoading}
                  questionMarkIcon={QuestionMarkIcon}
                  handleOpen={setInfoDialog}
                  exploreSectionEnabled={exploreSectionEnabled}
                  handleExplore={setExploreSectionEnabled}
                  score={latestScore}
                  assessmentScores={assessmentScores}
                  handleStarModal={setShortStarModalOpen}
                  scoreChartData={scoreChartData}
                />
              </Grid>
              <Grid item xs={12} md={6} className={styles.marginTopHorizontalGammaSize}>
                <div className={styles.areaChartContainer}>
                  <DashboardAreaChart
                    loading={assessmentScoresLoading && shortStarScoreDataLoading}
                    scoreChartData={scoreChartData}
                    handleStarModal={setShortStarModalOpen}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={3} className={styles.marginTopHorizontalGammaSize}>
                <ReflectionSection
                  loading={reflectionsLoading}
                  questionMarkIcon={QuestionMarkIcon}
                  quoteIcon={Quote}
                  userReflections={userReflections}
                  handleOpen={setInfoDialog}
                  handleStarModal={setShortStarModalOpen}
                />
              </Grid>

              {exploreSectionEnabled && (
                <DesktopExploreSection
                  loading={assessmentScoresLoading}
                  data={shortStarScoreData}
                  assessmentScores={assessmentScores}
                  handleStarModal={setShortStarModalOpen}
                />
              )}
              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <QuoteSection loading={quotesLoading} quoteIcon={Quote} data={quoteData} />
              </Grid>
              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <MusicSection loading={musicLoading} musicData={musicData} />
              </Grid>

              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                {dashboardActions?.plans?.length > 0 ? (
                  <ImprovePlan
                    dashboardActions={dashboardActions}
                    loading={improvementsLoading}
                    refetch={getActions}
                    userActiveImprovePlans={userImprovePlans}
                  />
                ) : (
                  <div className={styles.noPlansSection}>
                    <div className={styles.top}>
                      <h5>Your plans</h5>
                      <div className={styles.viewCompleteBtn}>
                        {userCompletedPlanCount > 0 && (
                          <DefaultButton
                            type="button"
                            className={styles.hsSecondaryBtn}
                            onClick={() => history.push('improve-plans?viewCompletedPlans=true')}
                          >
                            Completed Plans
                            <Img
                              src={DoubleTick}
                              alt="HS double tick Icon"
                              className={styles.hsStartIcon}
                            />
                          </DefaultButton>
                        )}
                      </div>
                    </div>
                    <div className={styles.imageCont}>
                      <Img src={EmptyPlans} alt="Empty Plans" className={styles.emptyPlansImg} />
                    </div>

                    <div className={styles.texts}>
                      <p>
                        Having a plan is crucial because it provides us with a roadmap to follow,
                        especially during those moments when motivation wanes.
                      </p>
                      <p>
                        Hapstar has you covered with tailored plans designed to align with your
                        goals and aspirations.
                      </p>

                      <div className={styles.button}>
                        <DefaultButton
                          variant="primary"
                          onClick={() => setShortStarModalOpen(true)}
                        >
                          Start
                        </DefaultButton>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <DeepDives data={deepDives} loading={wellbeingLoading} />
              </Grid>
            </Grid>

            <div className={styles.blogPostSection}>
              <h5>Recommended for you</h5>
              <Grid container spacing={4} className={styles.learningItemsContainer}>
                {recommendedPosts &&
                  recommendedPosts.length > 0 &&
                  recommendedPosts.map((item, index: number) => (
                    <Grid item xs={12} md={3} key={index}>
                      <LearningArticleCard
                        data={item}
                        loading={loadingPosts}
                        sectionType={item.id === -999 ? 'allLearnings' : 'posts'}
                        navigateTo="oldPosts"
                      />
                    </Grid>
                  ))}
              </Grid>

              {recommendedPosts?.length === 0 && !loadingPosts && <p>No posts</p>}
            </div>

            {companyConfigData.data.expertsEnabled && experts?.total > 0 && (
              <div className={styles.expertSection}>
                <h5>Book a session with an expert</h5>
                <ExpertsCarousel experts={experts} loading={expertsLoading} />

                {experts?.total === 0 && !expertsLoading && (
                  <p className={styles.noPostsText}>No experts</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.mobileOrder}>
        <div className={styles.infoSection}>
          <div className="container">
            <div className={styles.employeeTopContainer}>
              {otherLoading ? (
                <>
                  <Skeleton
                    variant="text"
                    width="50%"
                    height={80}
                    className={styles.loadUserWelcome}
                  />
                </>
              ) : (
                <h2 className={styles.title}>Welcome to Hapstar, {userDetails?.forename}</h2>
              )}

              <NotificationSection
                loading={improvementsLoading}
                icon={NotificationActiveIcon}
                button={
                  notificationAction
                    ? notificationAction?.details?.actionButton
                      ? notificationAction?.details?.actionButton
                      : 'Start Now'
                    : 'Create a plan'
                }
                handleClickAction={() => {
                  if (notificationAction) {
                    handleUpdateAction(
                      {
                        id: notificationAction?.details?.actionId,
                        actionType: notificationAction?.details?.actionType,
                        dueDate: null,
                        done: null,
                        removed: null,
                        start: true,
                      },
                      'Action started',
                    );

                    if (notificationAction?.details?.actionUrl) {
                      window.location.href = notificationAction?.details?.actionUrl;
                    }
                  } else {
                    setShortStarModalOpen(true);
                  }
                }}
                improvePlan={notificationAction}
              />
            </div>
            <Grid container spacing={2} className={styles.progressSection}>
              <Grid item xs={12} md={6}>
                {dashboardActions?.plans?.length > 0 ? (
                  <ImprovePlan
                    dashboardActions={dashboardActions}
                    loading={improvementsLoading}
                    refetch={getActions}
                    userActiveImprovePlans={userImprovePlans}
                  />
                ) : (
                  <div className={styles.noPlansSection}>
                    <div className={styles.top}>
                      <h5>Your plans</h5>
                      <div className={styles.viewCompleteBtn}>
                        {userCompletedPlanCount > 0 && (
                          <DefaultButton
                            type="button"
                            className={styles.hsSecondaryBtn}
                            onClick={() => history.push('improve-plans?viewCompletedPlans=true')}
                          >
                            Completed Plans
                            <Img
                              src={DoubleTick}
                              alt="HS Docubletick Icon"
                              className={styles.hsStartIcon}
                            />
                          </DefaultButton>
                        )}
                      </div>
                    </div>
                    <div className={styles.imageCont}>
                      <Img src={EmptyPlans} alt="Empty Plans" className={styles.emptyPlansImg} />
                    </div>

                    <div className={styles.texts}>
                      <p>
                        Having a plan is crucial because it provides us with a roadmap to follow,
                        especially during those moments when motivation wanes.
                      </p>
                      <p>
                        Hapstar has you covered with tailored plans designed to align with your
                        goals and aspirations.
                      </p>

                      <div className={styles.button}>
                        <DefaultButton
                          variant="primary"
                          onClick={() => setShortStarModalOpen(true)}
                        >
                          Start
                        </DefaultButton>
                      </div>
                    </div>
                  </div>
                )}
              </Grid>

              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <QuoteSection loading={quotesLoading} quoteIcon={Quote} data={quoteData} />
              </Grid>

              <Grid item xs={12} md={3} className={styles.marginTopHorizontal}>
                <ScoreGaugeSection
                  loading={scoreGaugeLoading}
                  questionMarkIcon={QuestionMarkIcon}
                  handleOpen={setInfoDialog}
                  exploreSectionEnabled={exploreSectionEnabled}
                  handleExplore={setExploreSectionEnabled}
                  score={latestScore}
                  assessmentScores={assessmentScores}
                  handleStarModal={setShortStarModalOpen}
                  assessmentScoresLoading={assessmentScoresLoading}
                  scoreChartData={scoreChartData}
                />
              </Grid>

              {exploreSectionEnabled && (
                <DesktopExploreSection
                  loading={assessmentScoresLoading}
                  data={deepDives}
                  assessmentScores={assessmentScores}
                  handleStarModal={setShortStarModalOpen}
                />
              )}

              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <MusicSection loading={musicLoading} musicData={musicData} />
              </Grid>

              <Grid item xs={12} md={6} className={styles.marginTopHorizontal}>
                <DeepDives data={deepDives} loading={wellbeingLoading} />
              </Grid>

              <Grid item xs={12} md={3} className={styles.marginTopHorizontalGammaSize}>
                <ReflectionSection
                  loading={reflectionsLoading}
                  questionMarkIcon={QuestionMarkIcon}
                  quoteIcon={Quote}
                  userReflections={userReflections}
                  handleOpen={setInfoDialog}
                  handleStarModal={setShortStarModalOpen}
                />
              </Grid>
            </Grid>

            <div className={styles.blogPostSection}>
              <h5>Recommended for you</h5>
              <div className={styles.blogCardSection}>
                <Grid container spacing={4} className={styles.learningItemsContainer}>
                  {recommendedPosts &&
                    recommendedPosts.length > 0 &&
                    recommendedPosts.map((item, index: number) => (
                      <Grid item xs={12} md={3} key={index}>
                        <LearningArticleCard
                          data={item}
                          loading={loadingPosts}
                          sectionType={item.id === -999 ? 'allLearnings' : 'posts'}
                          navigateTo="oldPosts"
                        />
                      </Grid>
                    ))}
                </Grid>

                {recommendedPosts?.length === 0 && !loadingPosts && <p>No posts</p>}
              </div>
            </div>

            {companyConfigData.data.expertsEnabled && experts?.total > 0 && (
              <div className={styles.expertSection}>
                <h5>Book a session with an expert</h5>
                <ExpertsCarousel experts={experts} loading={expertsLoading} />

                {experts?.total === 0 && !expertsLoading && (
                  <p className={styles.noPostsText}>No experts</p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container">
        {allResponses.length > 0 && (
          <ThoughtsResponses
            allResponses={allResponses}
            handleUpdateResponse={updateResponseAction}
            handleSeenAndArchive={seenAndArchiveSpeakUp}
          />
        )}

        <div className={styles.bottomBlogs}>
          <h3 className={styles.postsTitle}>Latest Learning</h3>

          {posts?.length > 0 ? (
            <>
              <Grid container spacing={4} className={styles.learningItemsContainer}>
                {posts.map((post, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <LearningArticleCard
                      data={post}
                      loading={loadingPosts}
                      sectionType={post.id === -999 ? 'allLearnings' : 'posts'}
                      navigateTo="oldPosts"
                    />
                  </Grid>
                ))}
              </Grid>
              <div className={styles.postsBtnContainer}>
                <Link to="/posts" className={styles.moreLearningText}>
                  <div>More Learning</div>
                </Link>
              </div>
            </>
          ) : (
            <p>No posts</p>
          )}
        </div>
      </div>

      <PopUpModal
        open={infoDialog.open}
        setOpen={() => setInfoDialog(initInfoDialog)}
        top={infoDialog.top}
        right={infoDialog.right}
        firstDescription={infoDialog.firstDescription}
        description={infoDialog.description}
        title={infoDialog.title}
        overlay
      />
    </MainLayout>
  );
};

interface ActionPlan {
  id: number;
  userId: number;
  questionId: number;
  done: boolean;
  improvePlan: string;
  completeAt: string | null;
  progress: number;
  questionColor: string;
  questionIcon: string;
  createdAt: string;
  updatedAt: string;
  assessmentScore?: any;
  actions?: any;
}

interface UserReflection {
  id: number;
  assessmentId: number;
  reflection: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
}

interface AssessmentScore {
  responseId: number;
  questionId: number;
  assessmentId: number;
  questionScore: number;
  createdAt: string;
  updatedAt: string;
  questionColor: string;
}

interface ThoughtsInterface {
  data: ThoughtDataInterface[];
  pagination: PaginationInterface;
}

interface DeepDive {
  questionId: number;
  questionText: string;
  colorTheme: string;
  deepDiveId: number;
  latestAssessmentScore: number;
  icon: string;
  deepDiveName: string;
}

interface AssessmentScores {
  name: string;
  data: number[];
  color: string;
  createdAt: string[];
}

type CombinedCommentsArray = (ThoughtResponseInterface | SpeakUpResponseInterface)[];

interface ShortStarScoreInterface {
  questionId: number;
  questionText: string;
  colorTheme: string;
  latestAssessmentScore: number;
  icon: string;
}

export default EmployeeHome;
