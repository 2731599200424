import React from 'react';
import { Slider as DefaultSlider } from '@mui/material';
import { styled } from '@mui/material/styles';

import styles from './index.module.scss';

interface CustomizedSliderProps {
  colorCode?: string;
  backgroundColor?: string;
}

const CustomizedSlider = styled(DefaultSlider)<CustomizedSliderProps>(
  ({ colorCode = '#019DF2', backgroundColor = '#019DF2' }) => ({
    color: colorCode,
    height: 4,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor,
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  }),
);

const CustomSlider: React.FC<SliderProps & CustomizedSliderProps> = (props) => {
  const {
    sliderValue,
    handleSliderChange,
    defaultValue,
    step,
    marks,
    minValue,
    maxValue,
    valueLabel,
    className,
    colorCode,
    backgroundColor,
  } = props;

  return (
    <div className={className}>
      <CustomizedSlider
        aria-label="Small steps"
        defaultValue={defaultValue}
        step={step}
        marks={marks}
        min={minValue}
        max={maxValue}
        value={sliderValue}
        onChange={handleSliderChange}
        valueLabelDisplay={valueLabel}
        valueLabelFormat={(value: number) => `${value}`}
        colorCode={colorCode}
        backgroundColor={backgroundColor}
      />
    </div>
  );
};

CustomSlider.defaultProps = {
  className: styles.root,
  minValue: 0,
  maxValue: 10,
  sliderValue: 7,
  step: 1,
  defaultValue: 7,
  valueLabel: 'on',
  colorCode: '#019DF2',
  backgroundColor: '#019DF2',
};

interface SliderProps {
  sliderValue?: number;
  handleSliderChange: any;
  defaultValue?: number;
  step?: number;
  marks: boolean;
  minValue?: number;
  maxValue?: number;
  valueLabel?: 'on' | 'off' | 'auto';
  className?: string;
}

export default CustomSlider;
