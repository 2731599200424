import { getScores } from '@api/assessment';
import DefaultButton from '@components/Atoms/DefaultButton';
import Button from '@components/Button';
import PopUpModal from '@components/PopUpModal';
import Star, { WingDetails } from '@components/Star';
import wingDetails from '@config/star.config.json';
import { useGlobalState } from '@context/GlobalStore';
import { SecondaryNavigationContext } from '@context/SecondaryNavigationContext';
import { ColorPalette } from '@enums/Colors';
import { parseStarDetails } from '@helpers/parser.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { CreateStarQuestionInterface } from '@interfaces/index';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { Question, deepDiveWizardActions } from 'store/Slices/DeepDiveWizardSlice';
import styles from './index.module.scss';

const StarFirstStep: React.FunctionComponent<StarWithLabelsInterface> = (props) => {
  const { isEdit = false } = props;
  const reduxDispatch = useAppDispatch();

  const [state] = useGlobalState();
  const { isDesktop, isMobile } = useWindowDimensions();
  const [realWingDetails, setRealWingDetails] = useState<WingDetails[]>(wingDetails);
  const [open, setOpen] = useState(true);
  const { activeSubNav } = useContext(SecondaryNavigationContext);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const deepDiveWizard = useAppSelector((st: RootState) => st.deepDiveWizard);

  const parsedCategoryValues = useMemo(
    () =>
      deepDiveWizard.categoriesWithQuestions.map((category) => {
        const data =
          category.questions.length === 1
            ? Array(4).fill(category.questions[0].answeredScore ?? 7)
            : category.questions.map((q: Question) => q.answeredScore ?? 7);

        const questions = category.questions.map(
          (q: Question): CreateStarQuestionInterface => ({
            id: q.questionId,
            name: q.name,
            hint: q.questionInfo,
            score: q.answeredScore ?? 7,
            shareWith: q?.shareWith || 'mySelf',
            thoughtsText: '',
            scoreDifference: 0,
            color: q.color,
            specialWeighting: q.specialWeighting,
            isSelected: false,
          }),
        );

        return {
          id: category.id,
          name: category.name,
          color: category.color,
          darkColor: ColorPalette[category.color.toLocaleLowerCase()],
          lightColor: category.color,
          data,
          compareData: [],
          questions,
        };
      }),
    [deepDiveWizard.categoriesWithQuestions],
  );

  useEffect(() => {
    if (parsedCategoryValues.length > 0) {
      const updatedWingDetails = parsedCategoryValues.map((category) => {
        const categoryName = category.name;
        const scores = category.data;
        const { color } = category;

        return parseStarDetails(categoryName, color, scores, !isEdit);
      });
      setRealWingDetails(updatedWingDetails);
    }
  }, [isEdit, parsedCategoryValues]);

  useEffect(() => {
    const getScoresData = async () => {
      setIsFirstTime(true);

      try {
        const data = await getScores(activeSubNav.id);
      } catch (err: any) {
        if (JSON.parse(err.message).message) {
          setIsFirstTime(true);
        }
      }
    };

    if (activeSubNav.id === JSON.parse(sessionStorage.getItem('subNav') as any)?.id) {
      getScoresData();
    }
  }, [activeSubNav.id]);

  const handleClick = (step: number) => {
    localStorage.setItem('changeRequested', step as any);
  };

  const actionButtonHandler = async () => {
    reduxDispatch(deepDiveWizardActions.updateWizardStep('SCORE'));
    reduxDispatch(deepDiveWizardActions.updateWizardSubStep('QUESTIONS'));
    setOpen(false);
  };

  const spanStyle = { fontSize: isDesktop ? '20px' : '12px' };
  const editLabel = isDesktop ? 'Edit Score' : 'Edit';

  const updatedDynamicTitle = activeSubNav?.name || '';

  const onboardPopupTitle = `Welcome to your ${activeSubNav?.name
    ?.toLowerCase()
    ?.replace('star', '')} wellbeing deep dive`;

  const onboardPopupFirstDescription = 'Here you can uncover your strengths and areas for growth.';

  const onboardPopupDescription = (
    <ul>
      <li>Score each driver</li>
      <li>Share your thoughts</li>
      <li>Choose your focus areas</li>
      <li>Set your actions</li>
    </ul>
  );

  return (
    <>
      {parsedCategoryValues.length > 0 && (
        <div className={styles.hsStarWithLabels} style={{ position: 'relative' }}>
          <h2 className={styles.categoryTitle}>{updatedDynamicTitle} wellbeing</h2>
          <div className={styles.btnContainer}>
            <DefaultButton
              onClick={() => {
                reduxDispatch(deepDiveWizardActions.updateWizardStep('SCORE'));
                reduxDispatch(deepDiveWizardActions.updateWizardSubStep('QUESTIONS'));
              }}
            >
              Let&apos;s begin
            </DefaultButton>
          </div>
          {isFirstTime && !isEdit && (
            <PopUpModal
              top="80px"
              right="0px"
              open={open}
              setOpen={setOpen}
              firstDescription={onboardPopupFirstDescription}
              description={onboardPopupDescription}
              title={onboardPopupTitle}
              buttonLabel="Let's begin"
              onActionClick={actionButtonHandler}
            />
          )}
          {state?.isUserOnBoarded && isEdit && (
            <PopUpModal
              top="80px"
              right="0px"
              open={open}
              setOpen={setOpen}
              description="Here you can share additional thoughts, reflect on and adjust your scores. If you’re happy click submit to finish your star."
              title="Nearly there!"
            />
          )}
          <div style={{ paddingBottom: '50px' }}>
            <table className="mx-auto">
              <tbody>
                <tr>
                  <td colSpan={3} align="center">
                    <span className="mb-3" style={spanStyle}>
                      {parsedCategoryValues[0].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(1)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td align="left">
                    <span style={spanStyle}>
                      {parsedCategoryValues[5].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(6)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                  <td rowSpan={2}>
                    <div
                      style={{
                        width: isDesktop ? '319px' : '180px',
                      }}
                    >
                      <Star wingDetails={realWingDetails} />
                    </div>
                  </td>
                  <td width="200">
                    <span style={spanStyle}>
                      {parsedCategoryValues[1].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(2)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td width="200" align="right">
                    <span style={spanStyle}>
                      {parsedCategoryValues[4].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(5)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                  <td width="200">
                    <span style={spanStyle}>
                      {parsedCategoryValues[2].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(3)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} align="center">
                    <span className="mt-3" style={spanStyle}>
                      {parsedCategoryValues[3].name}
                      {isEdit && (
                        <Button type="small" isButton onClick={() => handleClick(4)}>
                          {editLabel}
                        </Button>
                      )}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

interface StarWithLabelsInterface {
  isEdit?: boolean;
}

StarFirstStep.defaultProps = {
  isEdit: false,
};

export default StarFirstStep;
