import DefaultButton from '@components/Atoms/DefaultButton';
import Img from '@components/Atoms/Img';
import LoadingPage from '@components/LoadingPage/LoadingPage';
import MainLayout from '@layouts/MainLayout';
import React, { FunctionComponent, Suspense, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import CommentsIcon from '../../../assets/images/icons/comments-admin.svg';
import UsersIcon from '../../../assets/images/icons/users-admin.svg';
import styles from './index.module.scss';

const NavigationSection: FunctionComponent = () => {
  const history = useHistory();

  const handleNavigation = (path: string) => {
    history.push(path);
  };

  const navigationItems = useMemo(
    () => [
      {
        path: '/management/learning',
        icon: CommentsIcon,
        title: 'Configure learning section',
        buttonText: 'LEARNINGS',
      },
      {
        path: '/management/experts',
        icon: UsersIcon,
        title: 'Manage experts data',
        buttonText: 'EXPERTS',
      },
    ],
    [],
  );

  return (
    <div className={styles.navigationContainer}>
      {navigationItems.map((item, index) => (
        <div
          key={index}
          className={`${styles.navigationItem} ${styles[`navigationItem${index + 1}`]}`}
          onClick={() => handleNavigation(item.path)}
          role="button"
          tabIndex={0}
          onKeyPress={() => handleNavigation(item.path)}
        >
          <Img src={item.icon} alt={`${item.title} Icon`} className={styles.navigationIcon} />
          <h4>{item.title}</h4>
          <div className={styles.navigationBtn}>
            <DefaultButton>{item.buttonText}</DefaultButton>
          </div>
        </div>
      ))}
    </div>
  );
};

const ManagementLanding: FunctionComponent = () => {
  const [commentsResponses, setCommentsResponses] = useState<{
    respondedSpeakUps: number;
    respondedThoughts: number;
    notRespondedSpeakUps: number;
    notRespondedThoughts: number;
  }>({
    respondedSpeakUps: 0,
    respondedThoughts: 0,
    notRespondedSpeakUps: 0,
    notRespondedThoughts: 0,
  });

  return (
    <MainLayout title="Management Landing" useSuperAdminNav isSuperAdminPage secondaryNavEnabled>
      <div className={styles.mainContainer}>
        <Suspense fallback={<LoadingPage />}>
          <h2 className={styles.mainTitle}>Management portal</h2>
          <NavigationSection />
        </Suspense>
      </div>
    </MainLayout>
  );
};

export default ManagementLanding;
