import ProtectedRoute, { ProtectedTypes } from '@components/ProtectedRoute';
import ScrollToTop from '@components/ScrollToTop';
import AuthContextProvider from '@context/AuthContext';
import CheckYourInboxPage from '@pages/Authentication/CheckYourInbox';
import LoginPage from '@pages/Authentication/LoginPage';
import VerifyToken from '@pages/Authentication/VerifyToken';
import CreateStar from '@pages/CreateStar';
import HomePage from '@pages/HomePage';
import IntroductionPage from '@pages/IntroductionPage';
import RegistrationPage from '@pages/Register/RegistrationPage';
import ScoresPage from '@pages/ScoresPage/ScoresPage';
import WelcomePage from '@pages/WelcomePage';
import NotFound from '@pages/errors/NotFound';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import VerifyInviteToken from '@pages/Authentication/VerifyInviteToken';
import CreateStarContextProvider from '@context/CreateStarContext';
import { GlobalStateProvider } from '@context/GlobalStore';
import SecondaryNavigationContextProvider from '@context/SecondaryNavigationContext';
import CompleteRegistrationPage from '@pages/CompleteRegistration/CompleteRegistrationPage';
import EmployerCommentsPage from '@pages/EmployerCommentsPage';
import EmployerUsersPage from '@pages/EmployerPage';
import FeedbackAndSuggestions from '@pages/FeedbackAndSuggestions';
import PostPage from '@pages/Posts/Post';
import PostsPage from '@pages/Posts/Posts';
import WhatIsHapstar from '@pages/WhatIsHapstar';
// import mixpanel from 'mixpanel-browser';
import { getToken } from '@api/config';
import { logoutUser } from '@helpers/auth.helper';
import { useAppDispatch } from '@hooks/useAppDispatch';
import { useAppSelector } from '@hooks/useAppSelector';
import CreateStarNew from '@pages/CreateStarNew';
import InstantHelpPage from '@pages/EarlyWarning/InstantHelpPage';
import EmailSubscription from '@pages/EmailSubscription';
import EmployeeHome from '@pages/EmployeeHome';
import EmployerAnalytics from '@pages/EmployerAnalytics';
import EmployerHomepage from '@pages/EmployerHomepage';
import EmployerLanding from '@pages/EmployerLanding';
import AllExperts from '@pages/Experts/AllExperts';
import Expert from '@pages/Experts/Expert';
import ImprovePage from '@pages/Improve';
import LearningArticle from '@pages/Learning/LearningArticle';
import Learning from '@pages/Learning/Learnings';
import ManagementLanding from '@pages/Management/ManagementLanding';
import EmployerLearningConfig from '@pages/Management/LearningConfig';
import { UserRoles } from 'custom.d';
import { RootState } from 'store';
import { getCompanyConfigurations } from 'store/Actions/CompanyConfigAction';
import { getUserStatusData } from 'store/Actions/UserStatusAction';
import ExpertsManagement from '@pages/Experts/ExpertsManagement';

const App: React.FunctionComponent = () => {
  // Initialise Mixpanel, debug mode enabled in dev environment only
  const user = localStorage.getItem('user');
  const parsedUser = JSON.parse(user || '{}');
  const dispatch = useAppDispatch();
  const token = getToken();
  const companyConfigData = useAppSelector((state: RootState) => state.companyConfig);
  const isLoading = useAppSelector((state: RootState) => state.userStatus.loading);
  const isStatusError = useAppSelector((state: RootState) => state.userStatus.error);
  const isCompanyConfigError = useAppSelector((state: RootState) => state.companyConfig.error);
  const isInstantHelpError = useAppSelector((state: RootState) => state.instantHelp.error);
  const isStatusLoaded = useAppSelector((state: RootState) => state.userStatus.isLoaded);

  useEffect(() => {
    if (token) {
      dispatch(getUserStatusData());
    }

    if (isStatusError) {
      logoutUser();
    }
  }, [dispatch, token, isStatusError]);

  useEffect(() => {
    if (token && !companyConfigData.isLoaded) {
      dispatch(getCompanyConfigurations());
    }

    if (isCompanyConfigError) {
      logoutUser();
    }
  }, [dispatch, companyConfigData.isLoaded, token, isCompanyConfigError]);

  // useEffect(() => {
  //   mixpanel.track_pageview({
  //     current_page_title: window.document.title,
  //   });
  // }, []);

  if (token) {
    if (isLoading || !isStatusLoaded) {
      return (
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only" />
        </div>
      );
    }

    if (isStatusError || isCompanyConfigError || isInstantHelpError) {
      logoutUser();
    }
  }

  return (
    <Router>
      <ScrollToTop />
      <HelmetProvider>
        <AuthContextProvider>
          <GlobalStateProvider>
            <SecondaryNavigationContextProvider>
              <CreateStarContextProvider>
                <Switch>
                  <ProtectedRoute exact path="/" component={EmployeeHome} />
                  <ProtectedRoute
                    exact
                    path="/employer/old/homepage"
                    component={EmployerHomepage}
                  />
                  <ProtectedRoute exact path="/employer/homepage" component={EmployerLanding} />
                  <ProtectedRoute exact path="/employer/analytics" component={EmployerAnalytics} />
                  <ProtectedRoute path="/employer/users" component={EmployerUsersPage} />
                  <ProtectedRoute path="/employer/comments" component={EmployerCommentsPage} />

                  {parsedUser && parsedUser?.role === UserRoles.SUPER_ADMIN && (
                    <ProtectedRoute
                      exact
                      path="/management/learning"
                      component={EmployerLearningConfig}
                    />
                  )}

                  {parsedUser && parsedUser?.role === UserRoles.SUPER_ADMIN && (
                    <ProtectedRoute
                      exact
                      path="/management/homepage"
                      component={ManagementLanding}
                    />
                  )}

                  {parsedUser && parsedUser?.role === UserRoles.SUPER_ADMIN && (
                    <ProtectedRoute
                      exact
                      path="/management/experts"
                      component={ExpertsManagement}
                    />
                  )}

                  <ProtectedRoute path="/stars" component={HomePage} />
                  <ProtectedRoute path="/scores" component={ScoresPage} />

                  <ProtectedRoute path="/improve-plans" component={ImprovePage} />

                  {companyConfigData.data && companyConfigData.data.expertsEnabled && (
                    <ProtectedRoute path="/experts" component={AllExperts} />
                  )}

                  {companyConfigData.data && companyConfigData.data.expertsEnabled && (
                    <ProtectedRoute path="/expert/:expertId" component={Expert} />
                  )}

                  <ProtectedRoute
                    path="/welcome"
                    component={WelcomePage}
                    RedirectFunction={ProtectedTypes.AGREE_TERMS}
                  />
                  <ProtectedRoute
                    path="/introduction"
                    component={IntroductionPage}
                    RedirectFunction={ProtectedTypes.JOIN}
                  />
                  {/* <ProtectedRoute
                    path="/register/additionalInfo"
                    component={RegisterAdditionalInfo}
                  /> */}
                  <ProtectedRoute path="/create-a-star" component={CreateStar} />
                  <ProtectedRoute path="/new/create-a-star" component={CreateStarNew} />

                  <ProtectedRoute path="/what-is-hapstar" component={WhatIsHapstar} />
                  <ProtectedRoute
                    path="/feedback-and-suggestions"
                    component={FeedbackAndSuggestions}
                  />

                  <ProtectedRoute path="/old/posts" component={PostsPage} />
                  <ProtectedRoute path="/old/post/:postId" component={PostPage} />

                  <ProtectedRoute path="/posts" component={Learning} />
                  <ProtectedRoute path="/post/:postId" component={LearningArticle} />

                  <Route path="/signup" exact component={RegistrationPage} />
                  <Route path="/signup/:companyURL" exact component={RegistrationPage} />
                  <ProtectedRoute
                    path="/complete-signup"
                    exact
                    component={CompleteRegistrationPage}
                    RedirectFunction={ProtectedTypes.REGISTERED}
                  />

                  {companyConfigData?.data?.showInstantHelp && (
                    <ProtectedRoute path="/wellbeing" exact component={InstantHelpPage} />
                  )}

                  <Route path="/login" exact component={LoginPage} />
                  <Route path="/login/:companyURL" exact component={LoginPage} />
                  <Route
                    path="/login/check-your-inbox/:email"
                    exact
                    component={CheckYourInboxPage}
                  />
                  <Route
                    path="/login/check-your-inbox/:email/:companyURL"
                    exact
                    component={CheckYourInboxPage}
                  />
                  <Route path="/login/verify/:userId/:token" component={VerifyToken} />
                  {/* <Route path="/invite/:userId/:token" component={VerifyToken} /> */}
                  {/* <Route path="/join/:userId/:token" component={VerifyInviteToken} /> */}

                  <Route path="/mobileapp" exact component={LoginPage} />

                  <Route path="/email-subscription" component={EmailSubscription} />

                  <Route path="*" component={NotFound} />
                </Switch>
              </CreateStarContextProvider>
            </SecondaryNavigationContextProvider>
          </GlobalStateProvider>
        </AuthContextProvider>
      </HelmetProvider>
    </Router>
  );
};

export default App;
