import homeActive from '../assets/images/icons/home-active.svg';
import homeInactive from '../assets/images/icons/home-inactive.svg';
import analyticsInactive from '../assets/images/icons/analytics-inactive.svg';
import analyticsActive from '../assets/images/icons/analytics-active.svg';
import commentsInactive from '../assets/images/icons/comments-inactive.svg';
import commentsActive from '../assets/images/icons/comments-active.svg';
import usersInactive from '../assets/images/icons/users-inactive.svg';
import usersActive from '../assets/images/icons/users-active.svg';
import learnActive from '../assets/images/icons/learn-active.svg';
import learnInactive from '../assets/images/icons/learn-inactive.svg';
import expertsActive from '../assets/images/icons/experts-active.svg';
import expertsInactive from '../assets/images/icons/experts-inactive.svg';

export default [
  {
    name: 'Home',
    path: '/management/homepage',
    exact: true,
    icon: homeInactive,
    activeIcon: homeActive,
  },
  {
    name: 'Learnings',
    mobileName: 'Learn',
    path: '/management/learning',
    exact: false,
    icon: learnInactive,
    activeIcon: learnActive,
  },
  {
    name: 'Experts',
    mobileName: 'Experts',
    path: '/management/experts',
    exact: false,
    icon: expertsInactive,
    activeIcon: expertsActive,
  },
];
