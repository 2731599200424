import { useState } from 'react';
import useQuery from './useQuery';

export default function useDevFeatureToggle() {
  const searchParams = useQuery();
  const checkEnabledInDev = searchParams.get('onlyDevFeature') === 'true';
  const [devEnabled, setDevEnabled] = useState(checkEnabledInDev);

  return devEnabled;
}
