export enum Colors {
  'skyBlue' = '#019DF2',
  'calmLilac' = '#BEAAF3',
  'sunYellow' = '#FCD300',
  'zingyGreen' = '#8EEA36',
  'aquaGreen' = '#06BF97',
  'warmOrange' = '#FF8A4A',
}

export const ColorPalette: { [key: string]: string } = {
  '#019df2': '#0072b0',
  '#beaaf3': '#6048a1',
  '#fcd300': '#d4b202',
  '#8eea36': '#4c8f0c',
  '#06bf97': '#069b7b',
  '#ff8a4a': '#d96525',
};
